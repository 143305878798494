// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { URL_BASE } from "../../config/config";

export interface IFormInput {
    factura_id: number;
    fecha_pago: string;
    monto_pagado: number;
    voucher_transferencia: string;
    estado_pago: string;   // "abono" | "pago total"
    cliente_id: number;
    monto_total: number;
    monto_abono?: number;
    month: number;
    year: number;
}

interface FormularioProps {
    onSubmit: (data: IFormInput) => void;
    errors: any;
}

const Formulario: React.FC<FormularioProps> = ({ onSubmit, errors }) => {
    const { register, setValue, watch, control } = useFormContext();
    const [facturas, setFacturas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [montoTotal, setMontoTotal] = useState(0);
    const [saldoRestante, setSaldoRestante] = useState(0);

    // Para determinar qué tipo de pago se ha seleccionado
    const [tipoPago, setTipoPago] = useState<"abono" | "pago total">("abono");

    const clienteId = watch("cliente_id");
    const selectedMonth = watch("month") || new Date().getMonth() + 1;
    const selectedYear = watch("year") || new Date().getFullYear();
    const montoPagado = watch("monto_pagado") || 0;

    useEffect(() => {
        const fetchClientes = async () => {
            try {
                const { data } = await axios.get(`${URL_BASE}/clientes?opcion=all`);
                setClientes(data);
            } catch (error) {
                console.error("Error fetching clientes:", error);
            }
        };

        fetchClientes();
        // Seteamos valores iniciales
        setValue("fecha_pago", new Date().toISOString().split("T")[0]);
        setValue("month", selectedMonth);
        setValue("year", selectedYear);
    }, [setValue, selectedMonth, selectedYear]);

    const fetchFacturas = async (clientId: number, month: number, year: number) => {
        try {
            const { data } = await axios.get(
                `${URL_BASE}/facturas?opcion=get&filtro[cliente_id]=${clientId}&month=${month}&year=${year}`
            );
            setFacturas(data);
        } catch (error) {
            console.error("Error fetching invoices data:", error);
        }
    };

    useEffect(() => {
        if (clienteId) {
            fetchFacturas(clienteId, selectedMonth, selectedYear);
        }
    }, [clienteId, selectedMonth, selectedYear]);

    const handleFacturaChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const facturaId = Number(event.target.value);
        setValue("factura_id", facturaId);

        const facturaSeleccionada = facturas.find(
            (factura) => factura.factura_id === facturaId
        );

        if (facturaSeleccionada) {
            try {
                const { data } = await axios.get(`${URL_BASE}/pagos-factura/${facturaId}`);
                const diferencia = data.diferencia; // saldo pendiente

                setMontoTotal(facturaSeleccionada.monto_total);
                setSaldoRestante(diferencia);

                // Si estamos en "pago total", llenamos automáticamente el campo
                if (tipoPago === "pago total") {
                    setValue("monto_pagado", diferencia > 0 ? diferencia : 0);
                } else {
                    // Si es abono, lo dejamos en blanco o en 0
                    setValue("monto_pagado", 0);
                }

            } catch (error) {
                console.error("Error fetching payment data:", error);
            }
        }
    };

    const handleTipoPagoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedType = e.target.value as "abono" | "pago total";
        setTipoPago(selectedType);

        if (selectedType === "pago total") {
            // Si hay saldo restante, lo asignamos directamente
            if (saldoRestante > 0) {
                setValue("monto_pagado", saldoRestante);
            }
            // Ajustamos estado_pago
            setValue("estado_pago", "pago total");
        } else {
            // Para abono, ponemos el monto en 0 (o lo dejamos manual)
            setValue("monto_pagado", 0);
            setValue("estado_pago", "abono");
        }
    };

    const handleMontoPagadoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value) || 0;

        // Evitamos que se introduzca un monto mayor al saldo
        if (value > saldoRestante) {
            setValue("monto_pagado", saldoRestante);
        } else {
            setValue("monto_pagado", value);
        }
    };

    // Mapeamos los clientes a opciones para React-Select
    const clientOptions = clientes.map((cliente) => ({
        value: cliente.cliente_id,
        label: `${cliente.nombre} ${cliente.apellido}`,
    }));

    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col-md-4 mb-10">
                    <label className="form-label">Cliente</label>
                    <Controller
                        name="cliente_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                options={clientOptions}
                                placeholder="Seleccione un cliente"
                                isClearable
                                // Valor actual basado en field.value
                                value={
                                    clientOptions.find(
                                        (option) => option.value === field.value
                                    ) || null
                                }
                                onChange={(selectedOption) => {
                                    field.onChange(
                                        selectedOption ? selectedOption.value : null
                                    );
                                }}
                            />
                        )}
                    />
                    {errors.cliente_id && (
                        <span className="text-danger">Este campo es obligatorio</span>
                    )}
                </div>

                <div className="col-md-4 mb-10">
                    <label className="form-label">Mes</label>
                    <select
                        className="form-select form-select-solid"
                        {...register("month")}
                    >
                        {[...Array(12)].map((_, index) => (
                            <option key={index + 1} value={index + 1}>
                                {new Date(0, index).toLocaleString("default", {
                                    month: "long",
                                })}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="col-md-4 mb-10">
                    <label className="form-label">Año</label>
                    <select
                        className="form-select form-select-solid"
                        {...register("year")}
                    >
                        {[...Array(5)].map((_, index) => (
                            <option key={index} value={selectedYear - index}>
                                {selectedYear - index}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 mb-10">
                    <label className="form-label">Nº de Factura</label>
                    <select
                        className="form-select form-select-solid"
                        {...register("factura_id", { required: true })}
                        onChange={handleFacturaChange}
                    >
                        <option value="">Seleccione una factura</option>
                        {facturas.map((factura) => (
                            <option
                                key={factura.factura_id}
                                value={factura.factura_id}
                            >
                                {factura.factura_id} - Monto: {factura.monto_total}
                            </option>
                        ))}
                    </select>
                    {errors.factura_id && (
                        <span className="text-danger">Este campo es obligatorio</span>
                    )}
                </div>

                <div className="col-md-6 mb-10">
                    <label className="form-label">Voucher de Transferencia</label>
                    <input
                        type="text"
                        className="form-control form-control-solid"
                        {...register("voucher_transferencia", { required: true })}
                    />
                    {errors.voucher_transferencia && (
                        <span className="text-danger">Este campo es obligatorio</span>
                    )}
                </div>
            </div>

            {/* Tipo de Pago */}
            <div className="row">
                <label className="form-label mb-2">Tipo de Pago</label>
                <div className="col-md-12 mb-10 d-flex gap-3">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="abono"
                            name="tipoPago"
                            value="abono"
                            checked={tipoPago === "abono"}
                            onChange={handleTipoPagoChange}
                        />
                        <label className="form-check-label" htmlFor="abono">
                            Abono
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="pagoTotal"
                            name="tipoPago"
                            value="pago total"
                            checked={tipoPago === "pago total"}
                            onChange={handleTipoPagoChange}
                        />
                        <label className="form-check-label" htmlFor="pagoTotal">
                            Pago Total
                        </label>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4 mb-10">
                    <label className="form-label">Monto Total de la Factura</label>
                    <input
                        type="number"
                        className="form-control form-control-solid"
                        value={montoTotal}
                        disabled
                    />
                </div>

                <div className="col-md-4 mb-10">
                    <label className="form-label">Saldo Restante</label>
                    <input
                        type="number"
                        className="form-control form-control-solid"
                        value={saldoRestante}
                        disabled
                    />
                </div>

                <div className="col-md-4 mb-10">
                    <label className="form-label">
                        {tipoPago === "abono" ? "Monto a Abonar" : "Monto a Pagar"}
                    </label>
                    <input
                        type="number"
                        className="form-control form-control-solid"
                        {...register("monto_pagado", { required: true })}
                        onChange={handleMontoPagadoChange}
                        // Deshabilitamos si es "pago total" para que no se cambie manualmente
                        disabled={tipoPago === "pago total"}
                    />
                    {errors.monto_pagado && (
                        <span className="text-danger">Este campo es obligatorio</span>
                    )}
                    {montoPagado > saldoRestante && (
                        <span className="text-danger">
              El monto no puede exceder el saldo restante.
            </span>
                    )}
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <button
                    type="submit"
                    className="btn btn-primary"
                    // Desactivamos el botón si no hay saldo o no se ha seleccionado factura
                    disabled={!watch("factura_id") || saldoRestante === 0}
                >
                    Generar
                </button>
            </div>
        </form>
    );
};

export default Formulario;
